// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-additional-products-js": () => import("./../../../src/pages/additional-products.js" /* webpackChunkName: "component---src-pages-additional-products-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cold-call-warning-js": () => import("./../../../src/pages/cold-call-warning.js" /* webpackChunkName: "component---src-pages-cold-call-warning-js" */),
  "component---src-pages-complaints-handling-procedure-js": () => import("./../../../src/pages/complaints-handling-procedure.js" /* webpackChunkName: "component---src-pages-complaints-handling-procedure-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-covid-19-statement-js": () => import("./../../../src/pages/covid-19-statement.js" /* webpackChunkName: "component---src-pages-covid-19-statement-js" */),
  "component---src-pages-credit-hire-and-credit-repair-js": () => import("./../../../src/pages/credit-hire-and-credit-repair.js" /* webpackChunkName: "component---src-pages-credit-hire-and-credit-repair-js" */),
  "component---src-pages-everton-fc-hospitality-js": () => import("./../../../src/pages/everton-fc-hospitality.js" /* webpackChunkName: "component---src-pages-everton-fc-hospitality-js" */),
  "component---src-pages-excess-protection-js": () => import("./../../../src/pages/excess-protection.js" /* webpackChunkName: "component---src-pages-excess-protection-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-full-privacy-policy-js": () => import("./../../../src/pages/full-privacy-policy.js" /* webpackChunkName: "component---src-pages-full-privacy-policy-js" */),
  "component---src-pages-guaranteed-replacement-vehicle-insurance-js": () => import("./../../../src/pages/guaranteed-replacement-vehicle-insurance.js" /* webpackChunkName: "component---src-pages-guaranteed-replacement-vehicle-insurance-js" */),
  "component---src-pages-hospitality-js": () => import("./../../../src/pages/hospitality.js" /* webpackChunkName: "component---src-pages-hospitality-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-key-cover-insurance-js": () => import("./../../../src/pages/key-cover-insurance.js" /* webpackChunkName: "component---src-pages-key-cover-insurance-js" */),
  "component---src-pages-landlord-legal-protection-js": () => import("./../../../src/pages/landlord-legal-protection.js" /* webpackChunkName: "component---src-pages-landlord-legal-protection-js" */),
  "component---src-pages-manchester-united-hospitality-js": () => import("./../../../src/pages/manchester-united-hospitality.js" /* webpackChunkName: "component---src-pages-manchester-united-hospitality-js" */),
  "component---src-pages-motor-breakdown-recovery-js": () => import("./../../../src/pages/motor-breakdown-recovery.js" /* webpackChunkName: "component---src-pages-motor-breakdown-recovery-js" */),
  "component---src-pages-motor-legal-expenses-js": () => import("./../../../src/pages/motor-legal-expenses.js" /* webpackChunkName: "component---src-pages-motor-legal-expenses-js" */),
  "component---src-pages-ogden-rate-js": () => import("./../../../src/pages/ogden-rate.js" /* webpackChunkName: "component---src-pages-ogden-rate-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-third-party-intervention-js": () => import("./../../../src/pages/third-party-intervention.js" /* webpackChunkName: "component---src-pages-third-party-intervention-js" */),
  "component---src-pages-uninsured-loss-recovery-js": () => import("./../../../src/pages/uninsured-loss-recovery.js" /* webpackChunkName: "component---src-pages-uninsured-loss-recovery-js" */),
  "component---src-templates-articles-template-js": () => import("./../../../src/templates/articles-template.js" /* webpackChunkName: "component---src-templates-articles-template-js" */)
}

